import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion, easeOut } from "framer-motion";

import { Typography, Button } from '@mui/material';
import sessionstorage from 'sessionstorage';

import Classes from "./SectionA.module.css";
import '../Home-new.css'

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import VantaBirdsEffect from './Vanta/VantaBirdsEffect';

function SectionA() {
  const [width, setWidth] = useState(window.innerWidth);
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const navigateToSignup = () => {
    navigate('/signup', { state: { data: "toSignup" } });
  }

  const navigateToUpload = async () => {
    await sessionstorage.setItem("navBtn", "Pitch Deck Dashboard")

    if (user) {

      navigate('/dashboard')
    } else {
      navigate('/login')
    }

  }

  const goToDemo = () => {
    navigate('/demo');
  }

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return (
    <>
      <section id='home' className={Classes.home} >
        <div className={Classes.customBox}>
          {/* <motion.div
            whileTap={{ scale: 0.90 }}
            initial={{ opacity: 0, y: 450 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: .5,
              ease: easeOut,
              delay: 1.5
            }}
          >
            <motion.div
              whileTap={{ scale: 0.95 }}
              className={Classes.firstText}
              onClick={navigateToSignup}
            >
              <h5>What’s new?   White-labeled screening tool {'->'}</h5>
            </motion.div>
          </motion.div> */}

          <motion.div
            initial={{ opacity: 0, y: 450 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: .5,
              ease: easeOut,
              delay: 1.7
            }}
          >
            <Typography variant="h3" component="h1" className={Classes.Txt1} >
              All-in-one program and deal
            </Typography>

            <Typography variant="h3" component="h2" >
              <div className={Classes.flex} >
                <span className={Classes.Txt1} > management platform </span>
              </div>
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: .5, // Total duration of the animation
              ease: easeOut, // Custom easing curve
              delay: 2 // Delay before animation starts
            }}
          >
            <Typography className={Classes.Txt2} >
              Save time and resources with Evala’s full suite of tools to manage your accelerator,
              <br />
              pitch competition, and investment firm.
            </Typography>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: .5, // Total duration of the animation
              ease: easeOut, // Custom easing curve
              delay: 2.3 // Delay before animation starts
            }}
            whileTap={{ scale: 0.90 }}
            className={Classes.home_btns_con}
          >
            <div className={Classes.home_btns}>
              {/* <div onClick={navigateToSignup}>
                  <Button className='pitchDeckBtn'> Get started for free </Button>
                </div>
                <p>OR</p>
                <a href='https://calendly.com/pitchscreenerai/30min?primary_color=0262df' rel='noreferrer' target='_blank' >
                  <Button className='browseBtn' > Book demo </Button>
                </a> */}
              {/* <p>Try for free. No credit card required.</p> */}
              <motion.div
                whileTap={{ scale: 0.97 }}
                whileHover={{ scale: 1.03 }}
              >
                <button className={Classes.startBtn} onClick={navigateToSignup} > <h5>Get started for free</h5> </button>
              </motion.div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1, // Total duration of the animation
              ease: easeOut, // Custom easing curve
              delay: 1
            }}
          >
            <div className={Classes.ecllipse} >
              <img src='./blueEllipse.png' alt='ecllipse' />
            </div>
          </motion.div>
        </div>
      </section>
      <div className={Classes.gradientBackground} >
        <LazyLoadImage
          effect="opacity"
          src='/bg3.png'
          className={Classes.card_inner_img}
        />
      </div>
    </>
  );
}

export default SectionA;